//* ---------- TYPES

import type { GlobalActionsWithData } from "../init/events-with-data.js";

//* ---------- MODULE IMPORTS

import makeFormRequest from "@helpers/form-request.js";
import makeServerResponse from "@helpers/render-server-response.js";
import Request from "@helpers/request.js";
import App from "../init/app.js";

//* ---------- EXPORTS

export default {
    execute: async (data: GlobalActionsWithData['contact-form-submitted']) => {
        const { form, submitBtn } = data;
        const formInputs = <ContactForm>form.elements;
        const formRequest = makeFormRequest({ form, submitBtn });

        const payload = {
            fullName: formInputs.fullname.value.trim(),
            email: formInputs.email.value.trim().toLowerCase(),
            message: formInputs.message.value.trim()
        };

        const response = await Request.send('/contact', 'POST', payload);


        const serverReponse = makeServerResponse({ overlayDialog: App.getAppComponents().overlayDialog });

        if (response.success) {
            formRequest.afterResponse(true);
            serverReponse.show({
                resp: response,
                respTitle: 'Message envoyé',
                respText: `Merci, votre message a été envoyé avec succès.`
            });
            return;
        }
        formRequest.afterResponse(false);
        serverReponse.show({
            resp: response,
            respTitle: 'Erreur',
            respText: response.error
        });
    }
}