//* ---------- TYPES

import type { GlobalActions } from "./events.js";
import type { GlobalActionsWithData } from "./events-with-data.js";

type ExecutedAction = {
    execute: () => Promise<void>
}
type ExecutedActionwWithData<T extends keyof GlobalActionsWithData> = Readonly<{
    execute: (data: GlobalActionsWithData[T]) => Promise<void>
}>
type Router = {
    [key in GlobalActions]: ExecutedAction;
};
type RouterWithData = {
    [key in keyof GlobalActionsWithData]: ExecutedActionwWithData<key>;
}

//* ---------- MODULE IMPORTS (only events)

import contactFormSubmitted from "../events/contact-form-submitted.js";

//* ---------- EVENT ROUTER

function makeEventRouter() {

    const router: Router = {

    }

    const routerWithData: RouterWithData = {
        'contact-form-submitted': contactFormSubmitted
    }

    return Object.freeze({
        get,
        getWithData
    })

    function get(action: GlobalActions) {
        return router[action];
    }

    function getWithData<T extends keyof RouterWithData>(action: T) {
        return routerWithData[action];
    }
};

//* ---------- EXPORTS

const EVENT_ROUTER = makeEventRouter();

export default EVENT_ROUTER;