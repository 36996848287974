//* ---------- TYPES

type Dependencies = {
    aLinks: HTMLAnchorElement[]
}

type Routes = Omit<RenderedRoutes, '/404' | '/500' | '/400' | '/change-password' | '/login' | '/reset-password'>;


//* ---------- MODULE IMPORTS (only utility/helper modules)

//* ---------- EXPORTS

export default function makeSPARouter({ aLinks }: Dependencies) {

    let initialized = false;

    return Object.freeze({
        init
    });

    function init() {
        if (initialized) return;
        const currentPath = window.location.pathname as keyof Routes;
        _updateState(currentPath);
        initialized = true;
    }

    // ---------- A LINKS

    function _findLinkByPath(path: keyof Routes) {
        return aLinks.find(a => a.getAttribute('href') === path);
    }

    function _toggleActiveLink(target: HTMLAnchorElement, toggleClass: 'active') {
        aLinks.forEach(a => a.classList.remove(toggleClass));
        target.classList.add(toggleClass);
    }

    function _updateLinkState(path: keyof Routes) {
        const link = _findLinkByPath(path);
        if (!link) return;
        _toggleActiveLink(link, 'active');
    }

    // ---------- STATE

    function _updateState(path: keyof Routes) {
        _updateLinkState(path);
    }
}