//* ---------- TYPES


//* ---------- MODULE IMPORTS

import makeLazyImages from "@global-components/lazy-images.js";
import makeOverlayDialog from "@global-components/overlay-dialog.js";
import { $ } from "@helpers/dom-selectors.js";
import makeNav from "./nav.js";
import makeLightboxImages from "@global-components/lightbox.js";

export default function makeAppComponents() {

    //* ---------- COMPONENT FACTORIES

    const overlayDialog = makeOverlayDialog({
        overlay: $<MU_GlobalOverlay>(document, '#overlay')[0]
    });

    const lazyImages = makeLazyImages();

    const nav = makeNav();

    const lightboxImages = makeLightboxImages({
        overlay: makeOverlayDialog({
            overlay: $<MU_LightboxOverlay>(document, '#lightboxOverlay')[0]
        })
    });

    //* ---------- EXPORTS

    return Object.freeze({
        nav,
        overlayDialog,
        lazyImages,
        lightboxImages
    });
}