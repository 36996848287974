//* ---------- TYPES

type T = {
    nav: MU_Nav,
    hamburger: MU_Hamburger
}

//* ---------- MODULE IMPORTS (only utility/helper modules)

import { $ } from "@helpers/dom-selectors.js";

//* ---------- EXPORTS

export default function makeNav() {

    let initialized = false;
    const nav = $<T['nav']>(document, 'nav')[0];
    const hamburger = $<T['hamburger']>(document, 'my-hamburger')[0];
    const toggleClass: T['hamburger']['toggleClass'] = 'active';

    return Object.freeze({
        init
    });

    function init() {
        if (initialized) return;
        hamburger.addEventListener('click', _onHamburgerClick);
        initialized = true;
    }

    function _onHamburgerClick() {
        if (hamburger.classList.contains(toggleClass)) {
            hamburger.classList.remove(toggleClass);
            nav.classList.remove(toggleClass);
        }

        else {
            hamburger.classList.add(toggleClass);
            nav.classList.add(toggleClass);
        }
    }
}