//* ---------- TYPES

import type { GlobalActionsWithData } from "./events-with-data.js";
import type { GlobalActions } from "./events.js";
import type { EventData } from "@helpers/event-with-data-creator.js";

//* ---------- MODULE IMPORTS

import EVENT_ROUTER from "./event-router.js";
import makeAppComponents from "../components/index.js";
import makeSPARouter from "./spa-router.js";
import { $, rootDoc } from "@helpers/dom-selectors.js";
import makeFormInputAnimation from "@global-components/forms/form-input-animation.js";
import makeForm from "@global-components/forms/form.js";
import APP_EVENTS_WITH_DATA from "./events-with-data.js";

//* ---------- EXPORTS

function makeApp() {

    let APP_COMPONENTS: ReturnType<typeof makeAppComponents>;

    window.addEventListener('view-transition-end', (e) => {
        _init();
    });

    _init();

    return Object.freeze({
        resize,
        executeEvent,
        executeEventWithData,
        getAppComponents
    })

    function _init() {
        const router = makeSPARouter({ aLinks: $<MU_NavLink>(document, '.nav-item') });
        router.init();
        APP_COMPONENTS = makeAppComponents();
        APP_COMPONENTS.nav.init();
        APP_COMPONENTS.lazyImages.init();
        APP_COMPONENTS.lightboxImages.init();

        //* ---------- CONTACT PAGE /contact

        const contactForm = $<MU_ContactForm>(document, '#contactForm')[0];
        if (!contactForm) return;
        makeFormInputAnimation({
            form: contactForm,
        });
        makeForm<GlobalActionsWithData>({
            form: contactForm,
            preventDefault: true,
            eventsWithData: APP_EVENTS_WITH_DATA,
            eventAction: 'contact-form-submitted'
        }).init();
    }

    function resize() {
        const vh = window.innerHeight / 100;
        rootDoc.style.setProperty("--vh", vh + "px");
    }

    async function executeEvent(action: GlobalActions) {
        const event = EVENT_ROUTER.get(action);
        await event.execute();
    }

    async function executeEventWithData<T extends GlobalActionsWithData, K extends keyof GlobalActionsWithData>(detail: EventData<T, K>) {
        const event = EVENT_ROUTER.getWithData(detail.action);
        await event.execute(detail.data);
    }

    function getAppComponents() {
        return APP_COMPONENTS;
    }
};

const App = makeApp();
export default App;