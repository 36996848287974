import makeEventWithData from "@helpers/event-with-data-creator.js";

export type GlobalActionsWithData = {
    'contact-form-submitted': {
        form: HTMLFormElement,
        submitBtn: HTMLButtonElement
    }
}

const APP_EVENTS_WITH_DATA = makeEventWithData<GlobalActionsWithData>();
export default APP_EVENTS_WITH_DATA;