//* ---------- TYPES


import type { GlobalActionsWithData } from "./init/events-with-data.js";
import type { GlobalActions } from "./init/events.js";
import type { AppEvent } from "@helpers/event-creator.js";
import type { AppEventWithData } from "@helpers/event-with-data-creator.js";

//* ---------- MODULE IMPORTS (only App & utility/helper modules)

import App from "./init/app.js";

//* ---------- ENTRY POINT IIFE

(function () {

    window.addEventListener('resize', App.resize);
    App.resize();

    window.addEventListener('app-event', (e) => {
        App.executeEvent((e as AppEvent<GlobalActions>['event']).detail.action);
    });
    window.addEventListener('app-event-with-data', (e) => {
        App.executeEventWithData((e as AppEventWithData<GlobalActionsWithData>).detail);
    });

    document.documentElement.addEventListener('touchstart', function (e) { });//hover behaviour on touch devices
})();
