//* ---------- TYPES

import type makeOverlayDialog from "./overlay-dialog.js";

type T = {
    target: MU_LightboxImage,
    imageContent: MU_LightboxContentImage
}

type Dependencies = {
    overlay: ReturnType<typeof makeOverlayDialog>
}

//* ---------- MODULE IMPORTS (only utility/helper modules)

import { createEl, createElWithText, createFragment, createImageEl } from "@helpers/dom-creators.js";
import { $, getAttr } from "@helpers/dom-selectors.js";


//* ---------- EXPORTS

export default function makeLightboxImages({ overlay }: Dependencies) {

    let initialized = false;
    const MAX_WIDTH = 1000;

    return Object.freeze({
        init
    })

    function init() {
        if (initialized) return;
        const targets = $<T['target']>(document, 'lightbox-image');
        targets.forEach(target => target.addEventListener('click', _onClick));
        initialized = true;
    }

    function _onClick(this: HTMLElement) {
        const src = getAttr<T['target']>(this, 'data-src');
        const alt = getAttr<T['target']>(this, 'data-alt');
        const heightAttr = getAttr<T['target']>(this, 'data-height');
        if (!src || !alt || !heightAttr) return;

        if (isNaN(Number(heightAttr))) return;

        const MAX_HEIGHT = Number(heightAttr);
        const maxWidth = Math.min(window.innerWidth - 64, MAX_WIDTH);
        const maxHeight = Math.min(window.innerHeight - 101.5, MAX_HEIGHT);

        const hero = new Image();
        hero.src = src;

        hero.onload = () => {
            let w = hero.width;
            let h = hero.height;
            if (hero.width > maxWidth || hero.height > maxHeight) {
                if ((hero.width / maxWidth) > (hero.height / maxHeight)) {
                    w = maxWidth;
                    h = hero.height / (hero.width / maxWidth);
                }
                else {
                    h = maxHeight;
                    w = hero.width / (hero.height / maxHeight);
                }
            }
            const width = `${w}px`;
            const wrapper = createEl<T['imageContent']['wrapper']>({
                tag: 'div'
            });
            const image = createImageEl<T['imageContent']['image']>({
                tag: 'img',
                attr: { src, alt, width }
            });
            const caption = createElWithText<T['imageContent']['caption']>({
                tag: 'lightbox-caption',
                text: alt
            });
            wrapper.append(image, caption);
            const fragments = [wrapper]
            const fragment = createFragment(fragments);
            overlay.render(fragment);
        }
    }

}